<template>
  <div class="spinner">
    <div class="spinner_back">
      <div class="spinner-border spinner-border-sm" role="status"></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.spinner {
  top: 0;
  position: fixed !important;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 1050;
  overflow: auto;
  left: 0;
}
.spinner_back {
  width: 100%;
  height: 100%;
  margin: auto;
  justify-content: center;
  display: flex;
  align-items: center;
}
</style>